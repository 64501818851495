import ApiModel from './ApiModel'

class images extends ApiModel {
    uploadImg(formData) {
        return this.upload('/images', formData);
    }
    getFolders(params) {
        return this.get('/images/get-folders', params);
    }
    createFolder(params) {
        return this.post('/images/create-folder', params);
    }
}

export default new images('/images');