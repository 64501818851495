import Api from "@/api/horo";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";

export default function news() {
    let models = ref([]);
    let route = useRoute()
    let router = useRouter()
    let baseUrl = process.env.VUE_APP_API;
    let title = ref("");
    let description = ref("");
    let text = ref("");
    let type = ref("");
    let author = ref("");
    let url = ref("");
    let typeOptions = ref([
        { value: 1, text: "Психоматрица" },
        { value: 2, text: "Зодиакальный гороскоп" },
        { value: 3, text: "Восточный гороскоп" },
    ]);

    const createHoro = async () => {
        await Api.createModel({
            description: description.value,
            title: title.value,
            text: text.value,
            type: type.value,
            url: url.value,
            author: author.value,
        });

        router.push("/news/");
    };

    const updateHoro = async () => {
        await Api.updateModel(route.params.id, {
            id: route.params.id,
            description: description.value,
            title: title.value,
            text: text.value,
            type: type.value,
            url: url.value,
            author: author.value,
        });

        alert("Гороскоп успешно обновлен");
    }


    const deleteHoro = async () => {
        let isDelete = confirm("Вы действительно хотите удалить гороскоп?");

        if (isDelete === true) {
            await Api.deleteModel(route.params.id);
            router.push("/horo/");
        }
    }


    const getHoro = async () => {
        let response = await Api.getModel(route.params.id);
        title.value = response.data.title;
        description.value = response.data.description;
        text.value = response.data.text;
        type.value = response.data.type;
        url.value = response.data.url;
        author.value = response.data.author;
    }

    const getAllHoro = async () => {
        let response = await Api.getModels({});
        models.value = response.data;
    }

    return {
        models, title, baseUrl, description, text, url, type, author, typeOptions, getAllHoro, createHoro, updateHoro, deleteHoro, getHoro,
    };
}

