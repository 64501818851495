<template>
  <div class="GalleryView">
    <div class="header">
      <span>Галерея</span>

      <div class="search">
        <img src="@/assets/icon1.svg" />
        <input type="text" placeholder="Поиск..." />
      </div>
    </div>

    <div class="currentFolder">
      <button @click="foldersViewShow = true">Выбрать папку</button>

      <span>Папка: {{ folder }}</span>

      <input type="text" v-model="folder" />
    </div>

    <div class="images">
      <ImageGallery
        v-for="dates in gallery"
        v-bind:key="dates.id"
        v-bind:dates="dates"
        @deleteImage="deleteImage"
      />
    </div>

    <span>Добавить изображение</span>
    <input type="file" class="textImage" @change="UploadImg" />
    <button @click="getImages()">Обновить</button>
    <FoldersView
      v-if="foldersViewShow"
      @close="closeFolder"
      @pickFolder="pickFolder"
    />
  </div>
</template>

<script>
import Api from "@/api/images.js";

import ImageGallery from "@/components/gallery/ImageView.vue";
import FoldersView from "@/components/gallery/FoldersView.vue";

export default {
  data() {
    return {
      gallery: [],
      folder: null,
      foldersViewShow: false,
    };
  },
  components: {
    ImageGallery: ImageGallery,
    FoldersView: FoldersView,
  },
  methods: {
    closeFolder() {
      this.foldersViewShow = false;
    },
    pickFolder(path) {
      this.foldersViewShow = false;
      this.folder = path;
      this.getImages();
    },
    async UploadImg() {
      var formData = new FormData();
      var imagefile = document.getElementsByClassName("textImage")[0];
      formData.append("image", imagefile.files[0]);
      formData.append("folder", this.folder);

      await Api.uploadImg(formData);
      let response = await Api.getModels({ folder: this.folder });
      this.gallery = response.data;
    },
    async deleteImage(id) {
      let isDelete = confirm("Вы действительно хотите удалить изображение?");

      if (isDelete === true) {
        await Api.deleteModel(id);

        let response = await Api.getModels({ folder: this.folder });
        this.gallery = response.data;
      }
    },
    async getImages() {
      let response = await Api.getModels({ folder: this.folder });
      this.gallery = response.data;
    },
  },
  async created() {
    await this.getImages();
  },
};
</script>