<template>
  <div class="FoldersView">
    <img class="cross" @click="close" src="@/assets/cross.png" />
    <span class="path"> {{ path }} </span>

    <div class="folders" v-if="folders && folders.folders">
      <div
        class="folder"
        :class="{ active: key == selectedFolder.num }"
        @click="selectFolder(folder, key)"
        v-for="(folder, key) in folders.folders"
        @dblclick="openFolder(folder, key)"
        :key="key"
      >
        {{ folder }}
      </div>
    </div>

    <div class="actions">
      <input type="text" v-model="folderName" />
      <!-- <button @click="openFolder">Открыть папку</button> -->
      <button @click="pickFolder">Выбрать папку</button>
      <button @click="createFolder">Создать папку</button>
      <button @click="back">Назад</button>
    </div>
  </div>
</template>

<script>
import Api from "@/api/images.js";

export default {
  data() {
    return {
      folders: null,
      selectedFolder: {},
      path: "",
      folderName: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    back() {
      let secondLastIndex = this.path.lastIndexOf(
        "/",
        this.path.lastIndexOf("/") - 1
      ); // Находим индекс предпоследнего '/'

      if (secondLastIndex !== -1) {
        this.path = this.path.substring(0, secondLastIndex); // Извлекаем подстроку до последнего '/'
      } else {
        this.path = "";
      }

      this.getFolder(this.path);
    },
    selectFolder(folder, key) {
      this.selectedFolder.num = key;
      this.selectedFolder.name = folder;
    },
    pickFolder() {
      this.$emit("pickFolder", this.path);
    },
    async createFolder() {
      await Api.createFolder({
        folderName: this.folderName,
        folder: this.path,
      });

      this.getFolder(this.path);
    },
    openFolder(folder, key) {
      this.selectedFolder.num = key;
      this.selectedFolder.name = folder;
      if (this.path !== "") {
        this.path += "/" + this.selectedFolder.name;
      } else {
        this.path += this.selectedFolder.name;
      }
      this.getFolder(this.path);
    },
    async getFolder(path) {
      let response = await Api.getFolders({ folder: path });
      this.folders = response.data;
    },
  },
  async created() {
    this.getFolder("");
  },
};
</script>