<template>
  <div class="PickGallery">
    <div class="PickGallery-content">
      <img class="cross" @click="close" src="@/assets/cross.png" />

      <button @click="back">Назад</button>

      <div
        class="folder"
        :class="{ active: key == selectedFolder.num }"
        v-for="(folder, key) in gallery.folders"
        @dblclick="openFolder(folder, key)"
        :key="key"
      >
        {{ folder }}
      </div>

      <ImagePick
        v-for="dates in gallery.images"
        v-bind:key="dates.id"
        v-bind:dates="dates"
        @pickImage="pickImage"
      />
    </div>
  </div>
</template>

<script>
import Api from "@/api/images.js";
import ImagePick from "@/components/gallery/ImagePick.vue";

export default {
  data() {
    return {
      gallery: [],
      selectedFolder: {},
      path: "",
    };
  },
  components: {
    ImagePick,
  },
  methods: {
    back() {
      let secondLastIndex = this.path.lastIndexOf(
        "/",
        this.path.lastIndexOf("/") - 1
      ); // Находим индекс предпоследнего '/'

      if (secondLastIndex !== -1) {
        this.path = this.path.substring(0, secondLastIndex); // Извлекаем подстроку до последнего '/'
      } else {
        this.path = "";
      }

      this.getFolder(this.path);
    },
    pickImage(image) {
      console.log(image);
      this.$emit("pickImage", image);
    },
    openFolder(folder, key) {
      this.selectedFolder.num = key;
      this.selectedFolder.name = folder;
      if (this.path !== "") {
        this.path += "/" + this.selectedFolder.name;
      } else {
        this.path += this.selectedFolder.name;
      }
      this.getFolder(this.path);
    },
    close() {
      this.$emit("close");
    },
    async getFolder(path) {
      let response = await Api.getFolders({ folder: path });
      this.gallery = response.data;
    },
    selectFolder(folder, key) {
      this.selectedFolder.num = key;
      this.selectedFolder.name = folder;
    },
  },
  async created() {
    this.getFolder("");
  },
};
</script>

